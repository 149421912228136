import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/finissimos.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import finissimoscover from "../images/finissimos-cover.png" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-salmon.png" // Tell webpack this JS file uses this image
import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import StopMotionCeviche from "../images/StopMotionCeviche.mp4"

const finissimosSalmao = () => (
<Layout>
   <SEO title="Peixe Fácil - Finíssimos de Salmão" />
   <div className="wrapper-bg-fini">
      <div className="center-col-bg">
         <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-fini">
      <div className="wrapper-container">
         {/* <div className="mobile-logo">
            <img src={logonew} alt="Logo" />
         </div> */}
         <div className="titulo absara blue-marine">
            <p>Finíssimos de Salmão</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> Uma escolha sofisticada e versátil que facilita as suas refeições.</p>
         </div>
      </div>
   </div>
   <div id="recipes-section-fini">
   {/* <div className="wrapper-section-2">
      <div className="col-1-mobile">
      <video className="video-class" preload='auto' controls autoPlay loop muted playsInline>
            <source src={StopMotionCeviche} type="video/mp4" />
          </video>
</div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Ceviche de Salmão
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Finíssimos de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Abacate</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1/2 Cebola Roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Lima</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Flor de Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20g de Gengibre Fresco</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20g de Coentros</span>

                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Esprema os citrinos para um recipiente e misture com flor de sal, gengibre e coentros picados.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Coloque o salmão a marinar durante 15min.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Corte a cebola roxa em fatias muito finas</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Disponha fatias de abacate num prato e coloque a marinada por cima juntamente com o salmão.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Finalize com as fatias de cebola roxa no topo e zestes de lima e limão</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            448
                        </div>
                        <div className="titulo sneak-regular">
                            39
                        </div>
                        <div className="titulo sneak-regular">
                           8
                        </div>
                        <div className="titulo sneak-regular">
                      8
                        </div>
                        <div className="titulo sneak-regular">
                         7
                        </div>
                        <div className="titulo sneak-regular">
                        16
                        </div>
                        <div className="titulo sneak-regular">
                         10
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-2">
        <video className="video-class" preload='auto' controls autoPlay loop muted playsInline>
            <source src={StopMotionCeviche} type="video/mp4" />
          </video>
        </div>
      </div> */}
    <div className="wrapper-section-3">
      <div className="col-1-mobile">
 
      </div>
     
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Salada de salmão 
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de folhas de espinafres baby</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Finíssimos de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Cenoura </span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Pepino</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Flor de sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">10cl de vinagre balsâmico</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20g de miolo de noz</span><br></br>


                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Salteie os Finíssimos de Salmão até dourar</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Emprate a base com as folhas de espinafres e coloque a cenoura e o pepino cortado em tirar</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Adicione o salmão e nozes picadas</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Tempere com azeite, vinagre balsâmico e flor de sal a gosto.</span><br></br>


                     </div>
                     </div>
                 </div>
                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            303
                        </div>
                        <div className="titulo sneak-regular">
                           24
                        </div>
                        <div className="titulo sneak-regular">
                           4
                        </div>
                        <div className="titulo sneak-regular">
                           5
                        </div>
                        <div className="titulo sneak-regular">
                         4
                        </div>
                        <div className="titulo sneak-regular">
                         17
                        </div>
                        <div className="titulo sneak-regular">
                         4
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-1">
 
 </div>
       
      </div>
      <div className="wrapper-section">
        <div className="col-1">

        </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Bruschetta de ricota com Endro
                 </p>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Fatias de pão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">100g de ricota</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">10g de endro</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta rosa Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Finíssimos de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Rúcula Q/B</span>

                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Misture a ricota com endro, sumo de limão, pimenta rosa e sal</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Barre a fatia de pão com a mistura de ricota e coloque as fatias de salmão por cima.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Leve ao forno durante 10 min a 180ºC</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Retire do forno e disponha raspas de limão, cebolinho picado e rúcula.</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            559
                        </div>
                        <div className="titulo sneak-regular">
                            23
                        </div>
                        <div className="titulo sneak-regular">
                           6
                        </div>
                        <div className="titulo sneak-regular">
                      67
                        </div>
                        <div className="titulo sneak-regular">
                         2
                        </div>
                        <div className="titulo sneak-regular">
                        22
                        </div>
                        <div className="titulo sneak-regular">
                        4
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
      </div>



  
   </div>
   <div className="wrapper-3-fini">
   <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default finissimosSalmao